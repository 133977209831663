import { Entrance, Slot, Size } from '~/src/types/api'
import { getClosestSlots } from './getClosestSlots'
import { getSizeValue } from './getSizeValue'

interface GetFittingSlotsParameters {
  entrance: Entrance
  slots: Slot[]
  size: Size
}

// Get the closest slot that's 1.) not filled and 2.) fitz the vehicle
const getFittingSlots = ({ entrance, slots, size }: GetFittingSlotsParameters): Slot[] => {
  const closest = getClosestSlots({ entrance, slots })
  const sizeValue = getSizeValue(size)
  return closest.filter((slot) => getSizeValue(slot.size) >= sizeValue).filter((slot) => slot.session == null)
}

export { getFittingSlots }
