import React from 'react'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import { Avatar, Button, Container } from '~/src/components'

const Navigation = () => {
  return (
    <>
      <Accent />

      <Container>
        <Nav>
          <NavLogo>Object-Oriented Mall</NavLogo>
          <NavMenu>
            {/* <NavMenuItem>
              <Button icon="chevron-down">Tools</Button>
            </NavMenuItem> */}

            <NavMenuItem>
              <Avatar src="https://caretv.sgp1.digitaloceanspaces.com/app-pulse/user-avatars/00e902c321180b125d94f5d8e8a0bd03bf01a036.png" />
            </NavMenuItem>
          </NavMenu>
        </Nav>
      </Container>
    </>
  )
}

const Accent = styled.div`
  border-top: 6px solid ${theme.colors.blue[500]};
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${theme.colors.neutral[200]};
`

const NavLogo = styled.h4`
  margin: 0;
  font-weight: bold;
`

const NavMenu = styled.div`
  display: flex;
  align-items: center;
`

const NavMenuItem = styled.div`
  &:not(:last-child) {
    margin-right: 16px;
  }
`

export { Navigation }
