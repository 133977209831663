import { intervalToDuration } from 'date-fns'
import { Slot } from '~/src/types/api'
import { pick } from 'lodash'

const time = {
  flat: 3
}

const fee = {
  flat: 40,
  exceeding: {
    s: 20,
    m: 60,
    l: 100
  },
  daily: 5000
}

type RoundedUpDuration = Pick<Duration, 'days' | 'hours' | 'minutes'>

// { day: 1, hours: 23, minutes: 40 } -> { day: 2, hours: 0, minutes: 0 }
const getRoundedUpDuration = (timeline: { start: number; end: number }): RoundedUpDuration => {
  const duration = intervalToDuration(timeline)

  const result = pick(duration, ['days', 'hours', 'minutes'])

  if (result.minutes) {
    result.hours = result.hours + 1
    result.minutes = 0
  }

  if (result.hours === 24) {
    result.days = result.days + 1
    result.hours = 0
  }

  return result
}

const getSlotSessionTotal = (slot: Slot) => {
  const { session } = slot

  const now = Date.now()

  // { day: 1, hours: 23, minutes: 40 } -> { day: 2, hours: 0, minutes: 0 }
  const duration = getRoundedUpDuration({ start: session.created_at, end: now })

  // We'll charge flat fee
  if (duration.days === 0 && duration.hours - time.flat <= 3) {
    return fee.flat
  }

  // We'll charge flat fee + chargable hours (excluding everything)
  if (duration.days === 0) {
    const chargableHours = Math.max(duration.hours - 3, 0)
    return fee.flat + fee.exceeding[slot.size] * chargableHours
  }

  const hourlyCharge = fee.exceeding[slot.size] * duration.hours

  const dailyCharge = fee.daily * duration.days

  // We'll have simpler charging rules (without the flat + chargable hours rules)
  return dailyCharge + hourlyCharge
}

export { getSlotSessionTotal }
