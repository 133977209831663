import React, { useEffect, useState } from 'react'
import { intervalToDuration, formatDuration } from 'date-fns'
import { useInterval } from 'react-use'

interface ElapsedTimeProps {
  value: number
  from?: number
  interval?: number | null
}

const ElapsedTime: React.FC<ElapsedTimeProps> = ({ value, from, interval = 60000 }) => {
  const getElapsedTime = () => {
    return formatDuration(intervalToDuration({ start: value, end: from || Date.now() }))
      .replace(/\s(years|year)/g, 'y')
      .replace(/\s(months|month)/g, 'M')
      .replace(/\s(days|day)/g, 'd')
      .replace(/\s(hours|hour)/g, 'h')
      .replace(/\s(minutes|minute)/g, 'm')
      .replace(/([0-9]+)\s(seconds|second)/g, '')
  }

  const [elapsedTime, setElapsedTime] = useState(getElapsedTime)

  useInterval(() => {
    setElapsedTime(getElapsedTime)
  }, interval)

  return <span>{elapsedTime || '—'}</span>
}

export { ElapsedTime }
