import styled from 'styled-components'
import { theme } from '~/src/theme'

interface HeadingLabelProps {
  variant?: 'default' | 'main'
}

const HeadingLabel = styled.span<HeadingLabelProps>`
  display: block;
  width: 100%;
  color: ${(props) => (props.variant === 'main' ? theme.colors.neutral[900] : theme.colors.neutral[500])};
  font-size: ${theme.fontSizes.sm}px;
  font-weight: bold;
  text-transform: uppercase;
`

export { HeadingLabel }
