import { Size } from '~/src/types/api'

const rank: Record<Size, number> = {
  s: 1,
  m: 2,
  l: 3
}

// Allow sorting by size
const getSizeValue = (size: Size) => {
  return rank[size]
}

export { getSizeValue }
