import React from 'react'
import styled from 'styled-components'
import { useApp } from '~/src/app/AppContext'
import { Container, Select } from '~/src/components'
import { ID } from '~/src/types/api'
import { ParkModal } from './ParkModal'

const Toolbar = () => {
  const { entrances, selectedEntrance, selectEntrance } = useApp()

  const handleSelectEntrance = (id: ID) => {
    selectEntrance(entrances.find((e) => e.id === id))
  }

  return (
    <>
      <Container>
        <Wrapper>
          <WrapperSelect>
            <Select value={selectedEntrance.id} onChange={handleSelectEntrance}>
              {entrances.map((entrance) => {
                return (
                  <option key={entrance.id} value={entrance.id}>
                    {entrance.name}
                  </option>
                )
              })}
            </Select>
          </WrapperSelect>

          <div>
            <ParkModal />
          </div>
        </Wrapper>
      </Container>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const WrapperSelect = styled.h4`
  width: 240px;
`

export { Toolbar }
