import React, { useState, useMemo, useEffect } from 'react'
import { useApp } from '~/src/app/AppContext'
import { getFittingSlots, getMostRecentTransaction } from '~/src/app/utils'
import { Button, Input, Modal, ModalTitle, ModalActions, ModalContents, Spacer, Select } from '~/src/components'
import { Size, Slot } from '~/src/types/api'
import { ParkingDetails } from './ParkingDetails'

const ParkModal = () => {
  const { transactions, selectedEntrance, slots, parkSlot } = useApp()

  const [isOpen, setIsOpen] = useState(false)

  const [plate, setPlate] = useState('')

  const [size, setSize] = useState<Size>('')

  useEffect(() => {
    if (isOpen) {
      setPlate('')
      setSize('')
    }
  }, [isOpen])

  const handleChangePlate = (value: string) => {
    setPlate(value.toUpperCase())
  }

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault()
    parkSlot({ plate, size })
    setIsOpen(false)
  }

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const fittingSlot: Slot | null = useMemo(() => {
    if (!size) return null
    return getFittingSlots({ entrance: selectedEntrance, slots, size })[0]
  }, [selectedEntrance, slots, size])

  const transaction = useMemo(() => {
    return getMostRecentTransaction({ transactions, slots, plate })
  }, [transactions, slots, plate])

  const isValid = useMemo(() => {
    if (transaction) return true
    return size && plate && fittingSlot
  }, [transaction, size, plate, fittingSlot])

  return (
    <>
      <Button onClick={handleOpenModal}>Park Vehicle</Button>

      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <form onSubmit={handleSubmit}>
          <ModalTitle>Park Vehicle</ModalTitle>

          <ModalContents>
            <Input
              label="Vehicle Plate #"
              value={plate}
              onChange={handleChangePlate}
              autoFocus
              placeholder="ABC-1234"
            />
            <Spacer size={3} />

            {!transaction && (
              <>
                <Select label="Vehicle Size" value={size} onChange={setSize}>
                  <option value="">Select size</option>
                  <option value="s">Small</option>
                  <option value="m">Medium</option>
                  <option value="l">Large</option>
                </Select>
                <Spacer size={3} />
              </>
            )}

            <ParkingDetails transaction={transaction} slot={fittingSlot} size={size} />
          </ModalContents>

          <ModalActions>
            <Button variant="clear" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isValid}>
              {transaction ? 'Repark' : 'Park'}
            </Button>
          </ModalActions>
        </form>
      </Modal>
    </>
  )
}

export { ParkModal }
