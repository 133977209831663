import React from 'react'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import { HeadingLabel, Spacer } from '~/src/components'

interface Props {
  id?: string
  label?: string
  autoFocus?: boolean
  value?: string
  onChange: (value: string) => void
  placeholder?: string
  type?: string
}

const Input: React.FC<Props> = (props) => {
  const handleChange = (evt) => {
    props.onChange(evt.target.value)
  }

  return (
    <div>
      {props.label ? (
        <>
          <label htmlFor={props.id}>
            <HeadingLabel>{props.label}</HeadingLabel>
          </label>
          <Spacer />
        </>
      ) : null}
      <StyledInput
        id={props.id}
        type={props.type || 'text'}
        autoFocus={props.autoFocus}
        value={props.value}
        onChange={handleChange}
        placeholder={props.placeholder}
      />
    </div>
  )
}

const StyledInput = styled.input`
  display: flex;
  align-items: center;
  padding: 8px;
  height: 32px;
  width: 100%;
  font-weight: 500;
  background: transparent;
  border-radius: 4px;
  color: ${theme.colors.neutral[900]};
  outline: 0;
  border: 1px solid ${theme.colors.neutral[300]};

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    transition: 200ms box-shadow ease;
    box-shadow: 0px 0px 0px 3px ${theme.colors.blue[300]};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }
`

export { Input }
