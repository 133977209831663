import { Size } from '~/src/types/api'

const getSizeDisplayName = (size: Size) => {
  return {
    s: 'Small',
    m: 'Medium',
    l: 'Large'
  }[size]
}

export { getSizeDisplayName }
