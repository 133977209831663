import { Transaction, Slots } from '~/src/types/api'
import { differenceInMinutes } from 'date-fns'

//  A vehicle leaving the parking complex and returning within one hour based on their exit time must be charged a continuous rate, i.e. the vehicle must be considered as if it did not leave.
// Otherwise, rates must be implemented as described. For example, if a vehicle exits at 10:00 and returns at 10:30, continuous rate must apply.
const getMostRecentTransaction = ({ transactions, slots, plate }): Transaction | null => {
  if (!plate) {
    return null
  }

  const transaction = transactions.find((transaction) => {
    const isWithinTheHour = differenceInMinutes(Date.now(), transaction.created_at) <= 60
    return transaction.session.vehicle_plate === plate && isWithinTheHour
  })

  // Transaction is invalid while slot is taken
  if (transaction && slots.find((s) => s.id === transaction.slot.id).session) {
    return null
  }

  return transaction
}

export { getMostRecentTransaction }
