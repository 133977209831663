import React, { useMemo } from 'react'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import { useApp } from '~/src/app/AppContext'
import { getClosestSlots } from '~/src/app/utils'
import { Badge, Container, HeadingLabel, InsetBox, InsetBoxText, SlotDetails, Spacer } from '~/src/components'
import { UnparkModal } from './UnparkModal'

const SlotList: React.FC = () => {
  const { selectedEntrance, slots } = useApp()

  const openSlots = useMemo(() => {
    return getClosestSlots({ entrance: selectedEntrance, slots }).filter((slot) => slot.session === null)
  }, [selectedEntrance, slots])

  const filledSlots = useMemo(() => {
    return getClosestSlots({ entrance: selectedEntrance, slots }).filter((slot) => slot.session !== null)
  }, [selectedEntrance, slots])

  return (
    <Container>
      {openSlots.length ? (
        <>
          <Title>
            <TitleText>
              <HeadingLabel variant="main">Open Slots</HeadingLabel>
            </TitleText>
            <Badge>{openSlots.length}</Badge>
          </Title>
          <Spacer size={3} />
          <SlotContainer>
            <SlotHeading>
              <SlotHeadingColumn>Name</SlotHeadingColumn>
              <SlotHeadingColumn>Size</SlotHeadingColumn>
              <SlotHeadingColumn>Distance</SlotHeadingColumn>
              <SlotHeadingColumn></SlotHeadingColumn>
              <SlotHeadingColumn></SlotHeadingColumn>
            </SlotHeading>
            {openSlots.map((slot, i) => (
              <React.Fragment key={slot.id}>
                <SlotDetails entrance={selectedEntrance} slot={slot} action={<span />} />
                <Spacer size={2} />
              </React.Fragment>
            ))}
          </SlotContainer>{' '}
        </>
      ) : null}

      {!openSlots.length ? (
        <>
          <Title>
            <TitleText>
              <HeadingLabel variant="main">Open Slots</HeadingLabel>
            </TitleText>
            <Badge>{openSlots.length}</Badge>
          </Title>
          <Spacer size={3} />
          <SlotContainer>
            <InsetBox bg="darker">
              <InsetBoxText>No slots are open at the moment.</InsetBoxText>
            </InsetBox>
          </SlotContainer>
        </>
      ) : null}

      {filledSlots.length ? (
        <>
          <Spacer size={8} />
          <Title>
            <TitleText>
              <HeadingLabel variant="main">Filled Slots</HeadingLabel>
            </TitleText>
            <Badge>{filledSlots.length}</Badge>
          </Title>
          <Spacer size={3} />
          <SlotContainer>
            <SlotHeading>
              <SlotHeadingColumn>Name</SlotHeadingColumn>
              <SlotHeadingColumn>Size</SlotHeadingColumn>
              <SlotHeadingColumn>Distance</SlotHeadingColumn>
              <SlotHeadingColumn>Park Time</SlotHeadingColumn>
              <SlotHeadingColumn></SlotHeadingColumn>
            </SlotHeading>
            {filledSlots.map((slot, i) => (
              <React.Fragment key={slot.id}>
                <SlotDetails
                  entrance={selectedEntrance}
                  slot={slot}
                  key={slot.id}
                  action={<UnparkModal slot={slot} />}
                />
                <Spacer size={2} />
              </React.Fragment>
            ))}
          </SlotContainer>
        </>
      ) : null}

      <SlotFooter />
    </Container>
  )
}

const Title = styled.h5`
  display: flex;
  align-items: center;
  margin: 0;
`

const TitleText = styled.span`
  margin-right: 8px;
`

const SlotContainer = styled.div`
  width: 80%;
`

const SlotHeading = styled.div`
  display: flex;
  padding: 16px;
`

const SlotHeadingColumn = styled.div`
  width: 100%;
  font-size: ${theme.fontSizes.sm}px;
  font-weight: 500;
  color: ${theme.colors.neutral[500]};
  text-transform: uppercase;
`

const SlotFooter = styled.div`
  padding-bottom: 320px;
`

export { SlotList }
