import { addDays, addHours } from 'date-fns'
import { v4 as uuid } from 'uuid'
import { Entrance, Slot } from '~/src/types/api'
import { random, randomString, randomItem } from '~/src/utils'

// This mock is a representation of the given map:
//
//             ┌──────────┐
//             │   A     │
//     ┌────────┴──────────┴───────┐
//     │                         │
//     │ ┌─────────┐ │ ┌─────────┐ │
// ┌───┤ │  AA    │ │ │   BA    │ │
// │   │ └─────────┘ │ └─────────┘ │
// │ C │            │            │
// │   │ ┌─────────┐ │ ┌─────────┐ │
// │   │ │        │ │ │        │ │
// └───┤ └─────────┘ │ └─────────┘ │
//     │            │            │
//     │ ┌─────────┐ │ ┌─────────┐ │
//     │ │        │ │ │        │ │
//     │ └─────────┘ │ └─────────┘ │
//     │            │            │
//     │ ┌─────────┐ │ ┌─────────┐ │
//     │ │        │ │ │        │ │
//     │ └─────────┘ │ └─────────┘ │
//     │            │            │
//     │ ┌─────────┐ │ ┌─────────┐ │
//     │ │        │ │ │        │ │
//     │ └─────────┘ │ └─────────┘ │
//     │            │            │
//     │ ┌─────────┐ │ ┌─────────┐ │
//     │ │        │ │ │        │ │
//     │ └─────────┘ │ └─────────┘ │
//     │            │            │
//     │ ┌─────────┐ │ ┌─────────┐ │
//     │ │        │ │ │        │ │
//     │ └─────────┘ │ └─────────┘ │
//     │            │            │
//     │ ┌─────────┐ │ ┌─────────┐ │
//     │ │        │ │ │        │ │
//     │ └─────────┘ │ └─────────┘ │
//     │                         │
//     └────────┬─────────┬────────┘
//             │   B    │
//             └─────────┘
//

const mockEntrances: Entrance[] = [
  {
    id: uuid(),
    name: 'Entrance A'
  },
  {
    id: uuid(),
    name: 'Entrance B'
  },
  {
    id: uuid(),
    name: 'Entrance C'
  }
]

interface MakeSlotParameters {
  name: Slot['name']
  size: Slot['size']
  distances: number[]
  date?: Date
}

const makeSlot = ({ name, size, distances, date }: MakeSlotParameters): Slot => {
  return {
    id: uuid(),
    name,
    size,
    distances: mockEntrances.map((entrance, i) => {
      return {
        ...entrance,
        distance: distances[i]
      }
    }),
    session: date
      ? {
          id: uuid(),
          vehicle_plate: randomString(),
          vehicle_size: randomItem(['s', 'm', 'l']),
          created_at: +date,
          updated_at: +date
        }
      : null
  }
}

const now = new Date()
now.setMinutes(0)

const mockSlots: Slot[] = [
  // Row A
  makeSlot({ name: 'AA', size: 's', distances: [1, 8, 1] }),
  makeSlot({ name: 'AB', size: 's', distances: [2, 7, 1] }),
  makeSlot({ name: 'AC', size: 's', distances: [3, 6, 2], date: addHours(addDays(now, 1), 2) }),
  makeSlot({ name: 'AD', size: 's', distances: [4, 5, 3], date: addHours(addDays(now, 2), 2) }),
  makeSlot({ name: 'AE', size: 'm', distances: [5, 4, 4], date: addHours(addDays(now, 1), 2) }),
  makeSlot({ name: 'AF', size: 'm', distances: [6, 3, 5], date: addHours(addDays(now, 2), 2) }),
  makeSlot({ name: 'AG', size: 'm', distances: [7, 2, 6], date: addHours(now, 2) }),
  makeSlot({ name: 'AH', size: 'm', distances: [8, 1, 7] }),
  // Row B
  makeSlot({ name: 'BA', size: 'l', distances: [1, 8, 2] }),
  makeSlot({ name: 'BB', size: 'l', distances: [2, 7, 3] }),
  makeSlot({ name: 'BC', size: 'l', distances: [3, 6, 4], date: addDays(now, 1) }),
  makeSlot({ name: 'BD', size: 'l', distances: [4, 5, 5], date: addDays(now, 2) }),
  makeSlot({ name: 'BE', size: 'm', distances: [5, 4, 6], date: addHours(now, 4) }),
  makeSlot({ name: 'BF', size: 'm', distances: [6, 3, 7], date: addHours(now, 16) }),
  makeSlot({ name: 'BG', size: 'm', distances: [7, 2, 8] }),
  makeSlot({ name: 'BH', size: 'm', distances: [8, 1, 9] })
]

export { mockEntrances, mockSlots }
