import React from 'react'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import { HeadingLabel, Spacer } from '~/src/components'

interface InputStaticProps {
  label: string
  value: React.ReactNode
}

const InputStatic: React.FC<InputStaticProps> = ({ value, label }) => {
  return (
    <>
      <HeadingLabel>{label}</HeadingLabel>
      <Spacer />
      <Text>{value}</Text>
    </>
  )
}

const Text = styled.span`
  font-size: ${theme.fontSizes.sm}px;
  font-weight: 500;
`

export { InputStatic }
