import React from 'react'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import { useApp } from '~/src/app/AppContext'
import { Transaction, Slot, Size } from '~/src/types/api'
import {
  HeadingLabel,
  Spacer,
  SlotDetails,
  Icon,
  InsetBox,
  InsetBoxText,
  InputStatic,
  ElapsedTime
} from '~/src/components'
import { getSizeValue } from '~/src/app/utils'

interface ParkingDetailsProps {
  transaction: Transaction | null
  slot: Slot
  size: Size
}

const ParkingDetails: React.FC<ParkingDetailsProps> = ({ transaction, slot, size }) => {
  const { selectedEntrance } = useApp()

  if (transaction) {
    return (
      <>
        <HeadingLabel>Recent Parking Details</HeadingLabel>
        <Spacer />
        <InsetBox>
          <HeadingLabel>Parking Slot Details</HeadingLabel>
          <Spacer />
          <SlotDetails entrance={selectedEntrance} slot={transaction.slot} />
          <Spacer size={3} />

          <Details>
            <DetailItem>
              <InputStatic
                label="Parking Duration"
                value={<ElapsedTime value={transaction.session.created_at} from={transaction.created_at} />}
              />
            </DetailItem>

            <DetailItem>
              <InputStatic
                label="Parking Exit"
                value={
                  <>
                    <ElapsedTime value={transaction.created_at} interval={null} /> ago
                  </>
                }
              />
            </DetailItem>
          </Details>
        </InsetBox>
      </>
    )
  }

  if (size) {
    return (
      <>
        <HeadingLabel>Closest Open Slot</HeadingLabel>
        <Spacer />
        <InsetBox>
          {slot ? (
            <>
              <SlotDetails entrance={selectedEntrance} slot={slot} />
              <Spacer size={2} />
              {getSizeValue(slot.size) > getSizeValue(size) && (
                <WarningContainer>
                  <WarningIcon>
                    <Icon name="information-circle" />
                  </WarningIcon>
                  <WarningText>This slot is slightly larger than the vehicle.</WarningText>
                </WarningContainer>
              )}
            </>
          ) : (
            <InsetBoxText>No slots available</InsetBoxText>
          )}
        </InsetBox>
      </>
    )
  }

  return null
}

const Details = styled.div`
  display: flex;
`

const DetailItem = styled.div`
  width: 50%;
`

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
`

const WarningIcon = styled.div`
  margin-right: 8px;
  color: ${theme.colors.yellow[500]};
`

const WarningText = styled.span`
  color: ${theme.colors.neutral[500]};
  font-size: ${theme.fontSizes.sm}px;
  font-weight: 500;
`

export { ParkingDetails }
