import React, { ReactSVG } from 'react'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import { HeadingLabel, Icon, Spacer } from '~/src/components'

type SelectAttributeProps = Omit<React.HTMLAttributes<HTMLSelectElement>, 'onChange'>

type SelectOwnProps = {
  label?: string
  id?: string
  onChange: (v: string) => void
  children: React.ReactNode
}

type SelectProps = SelectAttributeProps & SelectOwnProps

const Select: React.FC<SelectProps> = ({ id, label, onChange, ...props }) => {
  const handleChange = (evt) => {
    onChange?.(evt.target.value)
  }

  return (
    <>
      {label ? (
        <>
          <label htmlFor={id}>
            <HeadingLabel>{label}</HeadingLabel>
          </label>
          <Spacer />
        </>
      ) : null}
      <Container>
        <SelectElement {...props} id={id} onChange={handleChange} />

        <SelectCaret>
          <Icon name="chevron-down" />
        </SelectCaret>
      </Container>
    </>
  )
}

const Container = styled.div`
  position: relative;
`

const SelectElement = styled.select`
  display: block;
  width: 100%;
  padding: 8px;
  padding-right: 32px;
  color: ${theme.colors.text};
  font-weight: 500;
  background: ${theme.colors.neutral[50]};
  border: 1px solid ${theme.colors.neutral[300]};
  border-radius: 4px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: 0;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    transition: 200ms box-shadow ease;
    box-shadow: 0px 0px 0px 3px ${theme.colors.blue[300]};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }
`

const SelectCaret = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  color: ${theme.colors.neutral[500]};
  pointer-events: none;
`

export { Select }
