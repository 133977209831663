import { Entrance, Slot } from '~/src/types/api'
import { getSizeValue } from './getSizeValue'

interface GetClosestSlotsParameters {
  entrance: Entrance
  slots: Slot[]
}

// Get slots sorted by distance and size
const getClosestSlots = ({ entrance, slots }: GetClosestSlotsParameters): Slot[] => {
  return [...slots].sort((a, b) => {
    const sizeA = getSizeValue(a.size)
    const sizeB = getSizeValue(b.size)
    if (sizeA > sizeB) return 1
    if (sizeA < sizeB) return -1

    const distanceA = a.distances.find((e) => e.id === entrance.id).distance
    const distanceB = b.distances.find((e) => e.id === entrance.id).distance
    if (distanceA > distanceB) return 1
    if (distanceA < distanceB) return -1
  })
}

export { getClosestSlots }
