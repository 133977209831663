import React, { useMemo } from 'react'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import { ElapsedTime } from '~/src/components'
import { Entrance, Slot } from '~/src/types/api'
import { getSizeDisplayName } from '~/src/app/utils'

interface SlotDetailsProps {
  entrance: Entrance
  slot: Slot
  action?: React.ReactNode
}

const SlotDetails: React.FC<SlotDetailsProps> = ({ entrance, slot, action }) => {
  const distance = useMemo(() => {
    return slot.distances.find((e) => e.id === entrance.id).distance
  }, [entrance, slot])

  const unit = useMemo(() => {
    return distance > 1 ? 'units' : 'unit'
  }, [distance])

  const size = useMemo(() => {
    return getSizeDisplayName(slot.size)
  }, [slot])

  return (
    <Wrapper>
      <Column>
        <Title>{slot.name}</Title>
      </Column>

      <Column>
        <Detail capitalized>{size}</Detail>
      </Column>

      <Column>
        <Detail>
          {distance} {unit} away
        </Detail>
      </Column>

      <Column>
        <Detail>{slot.session ? <ElapsedTime value={slot.session.created_at} /> : null}</Detail>
      </Column>

      {action ? (
        <Column>
          <Action>{action}</Action>
        </Column>
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: ${theme.fontSizes.sm}px;
  background: ${theme.colors.neutral[50]};
  border: 1px solid ${theme.colors.neutral[300]};
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
`

const Column = styled.div`
  display: flex;
  width: 100%;
`

const Title = styled.h4`
  margin: 0;
  font-weight: 600;
`

const Action = styled.div`
  margin-left: auto;
`

const Detail = styled.span<{ capitalized?: boolean }>`
  color: ${theme.colors.neutral[500]};
  font-weight: 500;
  ${(props) => props.capitalized && 'text-transform: uppercase'};
`

export { SlotDetails }
