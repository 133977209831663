import styled from 'styled-components'
import { theme } from '~/src/theme'

interface InsetBoxProps {
  bg: 'default' | 'darker'
}

const InsetBox = styled.div<InsetBoxProps>`
  padding: 16px;
  background: ${(props) => (props.bg === 'darker' ? theme.colors.neutral[200] : theme.colors.neutral[100])};
  border: 1px dashed ${theme.colors.neutral[300]};
  border-radius: 4px;
`

const InsetBoxText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.neutral[600]};
  font-weight: 600;
  font-size: ${theme.fontSizes.sm}px;
`

export { InsetBox, InsetBoxText }
