const theme = {
  fontFamily: {
    sans: `interstate, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
  },

  fontSizes: {
    sm: 12,
    regular: 16,
    lg: 24,
    heading: 48
  },

  colors: {
    text: '#18181b',
    bg: '#fff',

    neutral: {
      50: '#fff',
      100: '#f4f4f4',
      200: '#e4e4e7',
      300: '#d4d4d8',
      400: '#a1a1aa',
      500: '#71717a',
      600: '#52525b',
      700: '#3f3f46',
      800: '#27272a',
      900: '#18181b'
    },

    green: {
      500: '#00C165'
    },

    blue: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a'
    },

    red: {
      500: '#ef4444'
    },

    yellow: {
      500: '#EAB308'
    }
  }
}

export { theme }
