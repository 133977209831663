import React from 'react'
import 'modern-normalize/modern-normalize.css'
import { AppProvider } from './AppContext'
import { GlobalStyle, ModalRoot, Spacer } from '~/src/components'
import { Navigation } from './Navigation'
import { Toolbar } from './Toolbar'
import { SlotList } from './SlotList'

const App: React.FC = () => {
  return (
    <>
      <AppProvider>
        <Navigation />
        <Spacer size={3} />
        <Toolbar />
        <Spacer size={3} />
        <SlotList />
      </AppProvider>

      <ModalRoot />
      <GlobalStyle />
    </>
  )
}

export { App }
