import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import { theme } from '~/src/theme'
import compensate from 'scrollbar-compensate'
import './style.css'

interface ModalProps {
  isOpen: boolean
  onClose?: () => void
  children?: React.ReactNode
}

const Modal: React.FC<ModalProps> = (props) => {
  return (
    <ReactModal
      isOpen={props.isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={props.onClose}
      className="app-modal"
      overlayClassName="app-modal-overlay"
      bodyOpenClassName="app-modal-body is-open"
      closeTimeoutMS={200}>
      <ModalBody>{props.children}</ModalBody>
    </ReactModal>
  )
}

const ModalRoot = () => {
  useEffect(() => {
    compensate(['.app-modal-body.is-open'])
    ReactModal.setAppElement('#app-modal-mount')
  }, [])

  return <div id="app-modal-mount" />
}

const ModalBody = styled.div`
  background: #fff;
  outline: 0;
  width: 480px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid ${theme.colors.neutral[300]};
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.2);
`

const ModalTitle = styled.h4`
  padding: 16px;
  margin: 0;
  font-size: ${theme.fontSizes.regular}px;
  border-bottom: 1px solid ${theme.colors.neutral[200]};
`

const ModalActions = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${theme.colors.neutral[200]};

  & > * {
    width: auto;
  }
`

const ModalContents = styled.div`
  padding: 24px 16px;
`

export { Modal, ModalTitle, ModalActions, ModalContents, ModalRoot, ModalProps }
