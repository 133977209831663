import React from 'react'
import styled from 'styled-components'
import { theme } from '../../theme'

type Sizes = 'sm' | 'md' | 'lg'

interface Props {
  src: string
  size?: Sizes
}

export const Avatar: React.FC<Props> = ({ src, size = 'md' }) => {
  return <Image src={src} size={size} />
}

const sizes: Record<Sizes, number> = {
  sm: 24,
  md: 32,
  lg: 40
}

const Image = styled.img<{ size: Sizes }>`
  display: block;
  height: ${(props) => sizes[props.size]}px;
  width: ${(props) => sizes[props.size]}px;
  border-radius: 50%;
`
