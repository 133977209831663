import styled from 'styled-components'
import { theme } from '~/src/theme'

const Badge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  line-height: 0;
  color: ${theme.colors.neutral[800]};
  font-weight: 600;
  font-size: 10px;
  background: ${theme.colors.neutral[300]};
  border-radius: 50%;
`

export { Badge }
