import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { omit } from 'lodash'
import { theme } from '~/src/theme'
import { useApp } from '~/src/app/AppContext'
import { getSizeDisplayName, getSlotSessionTotal } from '~/src/app/utils'
import {
  Button,
  HeadingLabel,
  Modal,
  ModalTitle,
  ModalActions,
  ModalContents,
  Spacer,
  InputStatic,
  SlotDetails,
  ElapsedTime
} from '~/src/components'

const UnparkModal = ({ slot }) => {
  const { selectedEntrance, unparkSlot } = useApp()

  const [isOpen, setIsOpen] = useState(false)

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault()
    unparkSlot(slot)
    setIsOpen(false)
  }

  const handleOpenModal = () => setIsOpen(true)

  const handleCloseModal = () => setIsOpen(false)

  const formattedTotal = useMemo(() => {
    const total = getSlotSessionTotal(slot)
    return `${new Intl.NumberFormat().format(total)} PHP`
  }, [slot])

  const size = useMemo(() => {
    return getSizeDisplayName(slot.session.vehicle_size)
  }, [slot])

  // Omit session so that SlotDetail doesn't display parking time;
  // we display it separately
  const slotWithoutSession = useMemo(() => {
    return omit(slot, ['session'])
  }, [slot])

  return (
    <>
      <Button size="sm" onClick={handleOpenModal}>
        Unpark
      </Button>

      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <form onSubmit={handleSubmit}>
          <ModalTitle>Unpark Vehicle</ModalTitle>

          <ModalContents>
            <HeadingLabel>Slot Details</HeadingLabel>
            <Spacer size={2} />
            <SlotDetails entrance={selectedEntrance} slot={slotWithoutSession} />
            <Spacer size={4} />

            <Details>
              <DetailItem>
                <InputStatic
                  label="Vehicle Details"
                  value={
                    <span>
                      {slot.session.vehicle_plate} <DetailItemSub>({size})</DetailItemSub>
                    </span>
                  }
                />
              </DetailItem>

              <DetailItem>
                <InputStatic label="Parking Duration" value={<ElapsedTime value={slot.session.created_at} />} />
              </DetailItem>
            </Details>

            <Spacer size={4} />

            <TotalBox>
              <div>Total</div>
              <TotalBoxValue>{formattedTotal}</TotalBoxValue>
            </TotalBox>
          </ModalContents>

          <ModalActions>
            <Button variant="clear" onClick={handleCloseModal}>
              Cancel
            </Button>

            <Button type="submit">Confirm Payment</Button>
          </ModalActions>
        </form>
      </Modal>
    </>
  )
}

const TotalBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid ${theme.colors.neutral[200]};
  border-left: 4px solid ${theme.colors.blue[500]};
  border-radius: 4px;
`

const TotalBoxValue = styled.div`
  font-weight: 600;
`

const Details = styled.div`
  display: flex;
`

const DetailItem = styled.div`
  width: 50%;
`

const DetailItemSub = styled.span`
  color: ${theme.colors.neutral[400]};
  text-transform: uppercase;
`

export { UnparkModal }
