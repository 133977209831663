import React, { createContext, useContext } from 'react'
import immer from 'immer'
import { v4 as uuid } from 'uuid'
import { useLocalStorage } from 'react-use'
import { Entrance, Slot, Size, Transaction } from '~/src/types/api'
import { getFittingSlots, getMostRecentTransaction } from '~/src/app/utils'
import { mockEntrances, mockSlots } from './mock'

interface AppContextType {
  transactions: Transaction[]
  entrances: Entrance[]
  selectedEntrance: Entrance | null
  slots: Slot[]
  selectEntrance: (e: Entrance) => void
  parkSlot: (v: { plate: string; size: Size; slot: Slot }) => void
  unparkSlot: (slot: Slot) => void
}

const AppContext = createContext<AppContextType>({
  transactions: [],
  entrances: [],
  selectedEntrance: null,
  slots: [],
  selectEntrance: () => {},
  parkSlot: () => {},
  unparkSlot: () => {}
})

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [entrances] = useLocalStorage<Entrance[]>('mongo.entrances', mockEntrances)

  const [selectedEntrance, selectEntrance] = useLocalStorage<Entrance>('mongo.selectedEntrance', mockEntrances[0])

  const [slots, setSlots] = useLocalStorage<Slot[]>('mongo.slots', mockSlots)

  const [transactions, setTransactions] = useLocalStorage<Transaction[]>('mongo.transactions', [])

  const parkSlot: AppContextType['parkSlot'] = ({ plate, size }) => {
    const slot = getFittingSlots({ entrance: selectedEntrance, slots, size })[0]

    const transaction = getMostRecentTransaction({ transactions, slots, plate })

    if (transaction) {
      setSlots(
        immer(slots, (draft) => {
          const s = draft.find((s) => s.id === transaction.slot.id)
          s.session = { ...transaction.session }
        })
      )

      setTransactions(transactions.filter((t) => t.id !== transaction.id))
    } else {
      setSlots(
        immer(slots, (draft) => {
          const s = draft.find((s) => s.id === slot.id)

          s.session = {
            id: uuid(),
            vehicle_plate: plate,
            vehicle_size: size,
            created_at: Date.now(),
            updated_at: Date.now()
          }
        })
      )
    }
  }

  const unparkSlot: AppContextType['unparkSlot'] = (slot) => {
    setTransactions([
      ...transactions,
      {
        id: uuid(),
        slot: {
          ...slot,
          session: null
        },
        session: slot.session,
        total: 0,
        created_at: Date.now(),
        updated_at: Date.now()
      }
    ])

    setSlots(
      immer(slots, (draft) => {
        const s = draft.find((s) => s.id === slot.id)
        s.session = null
      })
    )
  }

  return (
    <AppContext.Provider
      value={{
        transactions,
        entrances,
        selectedEntrance,
        slots,
        selectEntrance,
        parkSlot,
        unparkSlot
      }}>
      {children}
    </AppContext.Provider>
  )
}

const useApp = () => {
  return useContext(AppContext)
}

export { AppProvider, useApp }
